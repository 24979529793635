<template>
  <div class="row mx-0 w-100 mb-5">
    <div class="row w-100 mx-0 mt-2 mt-lg-4 justify-content-center">
      <button class="path-item mt-2">
        <router-link :to="{ name: 'home' }" class="text-white"> Главная</router-link>
      </button>
      <span class="path-icon mx-2"> » </span>
      <button class="path-item mt-2">Бонусы</button>
    </div>

    <div class="row mx-0 mt-2 bonus-title justify-content-center w-100">
      <h1 class="text-white mx-2">Бонусы</h1>
    </div>

    <div class="col-12 col-sm-6  bonuses-block" v-if="bonuses" v-for="(bonus, index) in bonuses" :key="index">
      <div class="bonus">
        <img :src="getImage(bonus.image)">
      </div>
      <div class="bonus">
        <div class="bonus-btn">
          <button class="pink-btn more-details" @click="openModal(bonus)">ПОДРОБНЕЕ</button>
        </div>
      </div>
    </div>
    <BonusModal :text="text" :title="title"/>
  </div>
</template>
<script>
import BonusModal from "../components/modals/BonusModal";
import Config from "@/config";

export default {
  name: 'Bonuses',
  components: {
    BonusModal,
  },

  data() {
    return {
      text: null,
      title: null
    }
  },

  methods: {
    getImage(image) {
      return Config.mainStorageUrl + image;
    },
    openModal(bonus) {
      this.text = bonus.description
      this.title = bonus.title
      this.$bvModal.show('BonusModal')
    }
  },
  mounted() {
    this.$store.dispatch('fetchBonuses')
  },
  computed: {
    bonuses() {
      let bonuses = this.$store.getters.bonuses;
      if (!bonuses) {
        this.$store.dispatch('fetchBonuses')
      }
      return this.$store.getters.bonuses;
    }
  }
}
</script>

<style scoped>
.title h2 {
  font-weight: 700;
  font-size: 3vw;
  line-height: 95px;
  color: #FFFFFF;
  text-align: center;
}

.more-details {
  font-weight: 700;
  border: solid 2px #0ce8ae !important;
}

.bonus img {
  object-fit: none;
  width: 100%;
  height: 350px;
}

.bonus-btn button {
  width: 100%;
  height: 100%;
}

.bonus {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bonus-btn {
  width: 200px;
  height: 40px;
}

.bonus-title {
  margin-bottom: 85px;
}

@media only screen and (max-width: 1300px) {
  .bonus img {
    width: 100%;
  }
}

@media only screen and (max-width: 860px) {
  .title h2 {
    font-size: 16px;
  }

  .bonus img {
    width: 290px;
    height: 310px;
  }

  .bonus {
    height: 200px;
  }
}
</style>
  
  