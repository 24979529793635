<template>
    <div class="slider-container position-relative">
        <div class="row mx-0 carusel-block">
            <b-carousel :interval="3000" indicators class="w-100">
                <b-carousel-slide v-for="(slide, index) in slides" :img-src="slide" :key="index"
                                  :img-alt="'VolgaSlot'"></b-carousel-slide>
            </b-carousel>
        </div>
        <div class="indicators-area position-absolute w-100"></div>
    </div>

</template>

<script>
    export default {
        data() {
            return {
                slides: [
                    require('@/../public/images/slider/level_volga.png'),
                    require('@/../public/images/slider/tg.png'),
                    require('@/../public/images/slider/support.png'),
                    require('@/../public/images/slider/retro.png'),
                    require('@/../public/images/slider/chashback.png'),
                ],
            }
        }
    }
</script>

<style>
    .carousel-indicators {
        margin-bottom: -20px;
    }
    .carousel-inner{
     border-radius: 8px;
     background-image: url('../../../public/images/slider/slider_background.png');
     background-position: center;
     background-repeat: no-repeat;
    }
    .carousel-item img{
      width: 100%;
      height: 400px;
      object-fit: contain;
    }
    .carousel-indicators li {
        border-radius: 50%;
        height: 12px !important;
        width: 12px !important;
        border: none !important;
        background-color: black !important;
        outline: none !important;
    }

    .carousel-indicators li.active {
        background-color: #0ce8ae !important;
    }
    .rg_to_text{
      width: 100%;
      display: inline-block;
    }
    .slider_tg_title{
      font-size: 13px;
      font-weight: 600;
    }
    @media only screen and (max-width: 1024px) {
        .indicators-area,
        .carousel-indicators {
            display: none !important;
        }
    }

    @media screen and (max-width: 991px) and (min-width: 320px) {
      .rg_to_text{
        width: unset;
        margin-left: 20px;
      }
    }
    @media screen and (max-width: 800px) {
      .carousel-item img{
        height: 300px;
      }
    }
    @media screen and (max-width: 500px) {
      .rg_to_text{
        width: 100%;
        margin-left:unset;
      }
      .carousel-item img{
        height: 150px;
      }
      .slider_tg_title{
        font-size: 15px;
      }
    }
</style>
