<template>
    <b-modal
        id="freeRoundSelectorModal"
        ref="freeRoundSelectorModal"
        v-bind:title="round ? 'Вам доступно '+   round.count  + ' бесплатных вращения': '1'"
        size="md"
        :hide-footer="true"
        :no-close-on-esc="true"
        :no-close-on-backdrop="true"
        :hide-header-close="true"
        modal-class="main-modal page-main-block"
        header-class="text-white border-0 d-flex justify-content-center"
        header-close-variant="orange"
        title-class="text-center underlined-muted-title"
        body-class="pt-0"
    >
      <b-card no-body class="col-12 border-0 text-white bg-transparent">
        <div class="form-group">
          <div class="col-md-12 mx-auto mb-3 text-center px-0">
            <div class="col-12 mx-auto mb-3">
              <h6 class="text-center">
                Дата использования: {{round.use_date? round.use_date : 'неограничена'}} {{round.end_date ? '- ' +  round.end_date : ''}}
              </h6>
            </div>

            <h5 class="text-center mb-3" v-if="game">
              <span>{{ game.name }}</span>
            </h5>
            <div class="row text-center mb-3" v-if="game">
              <img
                  :src="$config.storageUrl +'/'+ game.picture.replace(/^\/|\/$/g, '')" alt="MagnetBet онлайн Казино"
                  class="mx-auto alive-popup-game-image"
              >
            </div>
            <div class="col-12 mx-auto mb-3" v-if="round.denomination">
              <h6 class="text-center">
                Деноминация - {{round.denomination? round.denomination : 1}}
              </h6>
            </div>

            <div class="row w-100 mx-0 mt-2 justify-content-center">
              <div class="col-12 col-lg-12 px-0 mx-0 mx-lg-2 mx-auto ">
                <a class="btn text-white btn-block freeround-toGame" @click="goToGameView()">Использовать</a>
              </div>
            </div>
          </div>
        </div>
      </b-card>
    </b-modal>
</template>

<script>
export default {
  name: "FreeRoundSelector",
  data() {
    return {
      round: false,
      result: false,
      game: null
    }
  },
  computed: {
    freeRoundData(){
      return this.$store.getters['freeRound']
    }
  },
  mounted () {
    this.$store.dispatch('checkUserFreeRound')
    this.round = this.$store.getters.freeRound
    this.game = this.round.game
    this.$root.$emit('bv::show::modal', 'freeRoundSelectorModal')
  },
  methods: {
    goHome () {
      return window.location = ""
    },
    goToGameView () {
      this.$root.$emit('bv::hide::modal', 'freeRoundSelectorModal')
      const gameName = this.game['name'].split(' ').join('-')
      return this.$router.push({
        name: 'TheGame',
        params: {
          gameName: gameName,
          gameId: this.game.id,
          game: this.game,
          roundId: this.freeRoundData.id,
          provider: this.round.system,
          isLive: this.game.isLive,
          restoring: false
        }
      })
    },
  }
}
</script>

<style scoped>
.alive-popup-game-image {
  height: 180px;
  box-shadow: 0 0 7px 2px #19428d;
  padding: 15px;
  object-fit: contain;
  margin-top: 5px;
  max-width: 65%;
}
.freeround-toGame{
    border: 1px solid #0ce8ae !important;
    border-radius: 30px;
    color: #0ce8ae;
}
.freeround-toGame:hover{
  background-color: #0ce8ae!important;
}

@media screen and (max-width: 450px) {
  .alive-popup-game-image {
    max-width: 260px;
  }
}
</style>
